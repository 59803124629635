import useStreamShopStatus from 'hooks/useStreamShopStatus'
import Script from 'next/script'
import { STATIC_KABUM } from 'settings/kernel'

function StreamShopScript() {
  const streamShopActive = useStreamShopStatus()

  if (streamShopActive) {
    return (
      <Script
        id='streamshop-sdk'
        dangerouslySetInnerHTML={{
          __html: `
            var liveshopSdkWidgetButtonOptions = {
              "bg":"${STATIC_KABUM}/conteudo/streamshop/streamshop-aovivo.png",
              "height":80,
              "width":80,
              "display":"cinema",
              "animation":true,
              "hml":false,
              "type":1,
              "useActiveVideosFrom":"kabum"
            };
            (function (i, s, o, g, r, a, m) {
              var p = new Promise(function(rs){return rs();});
              a = s.createElement(o);
              m = s.getElementsByTagName(o)[0];
              a.async = 1;
              a.src = g;
              a.onload = function(){return p.then(function(){return ss_widget_btn(liveshopSdkWidgetButtonOptions);});};
              m.parentNode.insertBefore(a, m);
            })(window, document, "script", "https://assets.streamshop.com.br/sdk/liveshop-sdk-widget-btn.min.js");
          `
        }}
        strategy='afterInteractive'
      />
    )
  }
}

export default StreamShopScript
