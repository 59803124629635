import { useEffect } from 'react'
import { notify } from 'react-notify-toast'
import { useDispatch } from 'react-redux'

import App from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import './globals.css'
import '@kbm/icons/css'
import StreamShopScript from 'components/StreamShop'

import {
  handtalkInstance,
  useAddresses,
  useHandtalk,
  useUser,
  useVisitor,
  useWishlist
} from '@k_frontend/core'
import { getCookie } from 'cookies-next'
import { useDeliveryProof } from 'hooks/useDeliveryProof'
import { useServicesStatus } from 'hooks/useServiceStatus/useServiceStatus'
import { CUSTOMER } from 'settings/endpoints'
import Kernel from 'settings/kernel'
import * as SEO from 'settings/seo'
import wrapper from 'state/store'
import theme from 'theme'
import ddRum from 'utils/ddRum'
import { exportAddToCart } from 'utils/exportAddToCart'
import { handleFlagBlackFriday } from 'utils/handleFlagBlackFriday'
import { handleCacheStore } from 'utils/handleStore'
import { getSearchVariation, setSearchVariation } from 'utils/searchVariation'
import { initGtm } from 'utils/tagManager'
import workWithUs from 'utils/workWithUs'
import { shallow } from 'zustand/shallow'
import { AppProviders } from '../providers/AppProviders'
import { openCSATWidget } from '../utils/CSAT'
import { GlobalStyle } from './_appStyles'

const useUserSelector = (state) => ({
  clientId: state.clientId,
  session: state.session,
  init: state.init,
  flagBlackFriday: state.flagBlackFriday,
  setFlagBlackFriday: state.setFlagBlackFriday,
  validateSession: state.validateSession,
  validSession: state.validSession,
  isPrime: state.isPrime
})

const useVisitorSelector = (state) => ({
  push: state.pushToDataLayer
})

const useAddressSelector = (state) => ({
  init: state.init
})

const MyApp = ({ Component, pageProps }) => {
  const isHandtalkActive = useHandtalk((state) => state.isHandtalkActive)
  const dispatch = useDispatch()
  const { cookieIsMobile } = pageProps
  const {
    clientId,
    session,
    init: initUser,
    setFlagBlackFriday,
    validateSession,
    validSession,
    isPrime
  } = useUser(useUserSelector, shallow)
  const { push: pushVisitor } = useVisitor(useVisitorSelector, shallow)

  const { init: initAddresses } = useAddresses(useAddressSelector, shallow)
  const { getWishlist } = useWishlist()
  const { verifyServicesStatus, verifyNoticeStatus, verifyWalletStatus } =
    useServicesStatus()
  const { getDeliveryProof, setDeliveryProof } = useDeliveryProof()

  async function initReviewAttendance() {
    await window.Genesys(
      'subscribe',
      'MessagingService.conversationDisconnected',
      function ({ data }) {
        if (
          data?.readOnly === true &&
          data?.message?.originatingEntity !== 'Bot'
        ) {
          try {
            openCSATWidget(data.message.id)
          } catch {
            return
          }
        }
        return ''
      }
    )
  }

  function handleLoadGenesys() {
    window.sendClientIdToChat(clientId)
    initReviewAttendance()
  }

  useEffect(() => {
    if (session && !validSession) {
      notify.show('Sua sessão expirou. Faça login novamente.', 'error')
    }
    const searchVariation = getSearchVariation()
    const deliveryProofProtocol = getDeliveryProof()

    if (session && !searchVariation) {
      setSearchVariation(session)
    }

    if (session && !deliveryProofProtocol) {
      setDeliveryProof(session)
    }
  }, [validSession, session])

  useEffect(() => {
    ddRum()
    validateSession(Kernel)
    initUser(Kernel)
    handleFlagBlackFriday(setFlagBlackFriday)
    if (clientId && session) initAddresses(Kernel, clientId, session)
    pushVisitor()
    getWishlist(
      clientId,
      session,
      `${Kernel.endpoint}${CUSTOMER.WISHLIST_DATA}`
    )
    initGtm()
    exportAddToCart(dispatch)
    workWithUs()
    verifyServicesStatus()
    verifyNoticeStatus()
    verifyWalletStatus()
  }, [])

  return (
    <>
      <Head>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0'
        />
        <title>{SEO.SITE_TITLE}</title>
        {isPrime && (
          <>
            <meta name='theme-color' content={theme.colors.black(900)} />
            <meta
              name='apple-mobile-web-app-status-bar-style'
              content={theme.colors.black(900)}
            />
          </>
        )}
      </Head>

      <Script
        src={`${Kernel.STATIC_KABUM}/conteudo/genesys/genesys.script.js`}
        type='application/javascript'
        id='genesysChat'
        data-chat-key={Kernel.DEPLOYMENT_ID_GENESYS}
        onLoad={() => handleLoadGenesys()}
        strategy='lazyOnload'
      />
      <Script
        type='application/javascript'
        src='https://survey.solucx.com.br/widget.js'
        strategy='lazyOnload'
      />

      {isHandtalkActive && (
        <Script
          strategy='afterInteractive'
          type='application/javascript'
          src='https://plugin.handtalk.me/web/latest/handtalk.min.js'
          onLoad={() => handtalkInstance()}
        />
      )}

      <StreamShopScript />

      <GlobalStyle />

      <AppProviders cookieIsMobile={cookieIsMobile}>
        <Component {...pageProps} />
      </AppProviders>
    </>
  )
}

MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext)
  const {
    ctx: { req, res }
  } = appContext
  const { cookies } = req || {}

  handleCacheStore(req, res)

  appProps.pageProps = {
    ...appProps.pageProps,
    cookieIsMobile: cookies?.isMobile === 'true'
  }
  useUser.setState({
    isPrime: getCookie('CP_1286400', { req, res })
  })

  return { ...appProps }
}

export default wrapper.withRedux(MyApp)
