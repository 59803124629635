import { HttpAxiosClient } from 'adapters/httpAxiosClient/httpAxiosClient'
import { NOTICE } from 'settings/endpoints'
import { StreamShopStatusResponse } from './streamshop.types'

export class StreamShopService {
  constructor(private readonly httpClient = new HttpAxiosClient()) {}

  async getStreamShopStatus(): Promise<boolean> {
    try {
      const { body } = await this.httpClient.request<StreamShopStatusResponse>({
        method: 'get',
        url: NOTICE.STREAM_SHOP
      })

      if (!body) {
        console.warn('Streamshop status request failed')
        return false
      }

      return body.streamshop_live
    } catch (e) {
      console.error('Unexpected error fetching streamshop status')
      return false
    }
  }
}
